<template>
  <div class="confirm-popover">
    <div class="confirm-popover__blur" />
    <div class="confirm-popover__content" :class="{ 'confirm-popover__content--dark': $vuetify.theme.dark }">
      <span class="confirm-popover__content__title">{{ title }}</span>
      <div v-if="isLoading" class="confirm-popover__content__buttons">
        <ui-loader class="confirm-popover__content__loader" />
      </div>
      <div v-else class="confirm-popover__content__buttons">
        <ui-button
          class="confirm-popover__content__buttons__no"
          button="primary"
          :label="$t('ui.confirmPopover.button.no').toUpperCase()"
          @click="onClickNo"
        />
        <ui-button
          class="confirm-popover__content__buttons__yes"
          button="primary"
          :label="$t('ui.confirmPopover.button.yes').toUpperCase()"
          @click="onClickYes"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'
import UiLoader from '@/components/UI/Loader.vue'

export default {
  name: 'UiConfirmPopover',
  components: {
    UiButton,
    UiLoader,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onClickNo(e) {
      this.$emit('onClickNo', e)
    },
    onClickYes(e) {
      this.$emit('onClickYes', e)
    },
  },
}
</script>

<style lang="scss" scoped>
.confirm-popover {
  position: absolute;
  width: 100%;
  height: 100%;

  &__blur {
    position: absolute;
    backdrop-filter: blur(10px);
    z-index: 50;
    width: 100%;
    height: 100%;
  }

  &__content {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 100;
    border-radius: 30px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
    background: white;
    width: 75%;
    min-width: 300px;
    height: 25%;
    min-height: 50px;
    backdrop-filter: none;

    &--dark {
      background-color: $interface-dark-grade-1-color;
    }

    &__title {
      margin-top: auto;
    }

    &__buttons {
      margin: auto;
      > button {
        margin: 0 8px;
        min-width: 78px;
        height: 32px;
        min-height: 32px;
        font-size: 14px;
        font-weight: 500;
      }
      &__no {
        color: #9696a4;
      }

      &__yes {
        border: 0;
        background: linear-gradient(to left, #1faae6, #449afd);
        color: white !important;
      }
    }
  }
}
</style>
