<template>
  <div class="question-answer-row" @click="disabledUpdate || !isMerchant ? false : onSelectOption(0)">
    <div class="question-answer-row__header">
      <img class="question-answer-row__header__picture" :src="item.author.profilePhotoUri" alt="" />
      <div class="question-answer-row__header__author">
        <span class="question-answer-row__header__author__name">{{ item.author.displayName }}</span>
        <span class="question-answer-row__header__author__type">{{ item.author.type }}</span>
      </div>
      <div class="question-answer-row__header__date-delete">
        <span class="question-answer-row__header__date">
          {{ date(item.createTime) }}
        </span>
        <ui-button
          class="question-answer-row__header__date-delete__button"
          v-if="isMerchant && !disabledDelete && !askForDelete"
          button="secondary"
          variant="error"
          icon="delete"
          :label="$t('gmbQaEdit.button.delete')"
          :iconOnly="true"
          :icon-small="true"
          @click.stop="askForDelete = true"
        />
        <div v-if="askForDelete" class="question-answer-row__header__date-delete__ask">
          <ui-button
            class="question-answer-row__header__ask__no"
            :label="$t('common.button.no')"
            button="secondary"
            variant="error"
            icon="close"
            :icon-only="true"
            :icon-small="true"
            @click.stop.prevent="askForDelete = false"
          />
          <ui-button
            class="question-answer-row__header__ask__yes"
            :label="$t('common.button.yes')"
            button="secondary"
            variant="success"
            icon="check"
            :icon-only="true"
            :icon-small="true"
            @click.stop.prevent="onSelectOption(1)"
          />
        </div>
      </div>
    </div>
    <div class="question-answer-row__question">
      <span class="question-answer-row__question__text">{{ item.text }}</span>
      <!-- <ui-button
        v-if="withUpVoteButton"
        v-bind:class="{ 'all-ready-up-voted': item.upvoteCount > 0 }"
        class="question-answer-row__question__upvote"
        button="secondary"
        icon="thumbs-up"
        :label="item.upvoteCount > 0 ? `${item.upvoteCount}` : ''"
      /> -->
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'

export default {
  name: 'QuestionAnswerRow',
  components: {
    UiButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    disabledDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledUpdate: {
      type: Boolean,
      required: false,
      default: false,
    },
    withUpVoteButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    ROLE_MERCHANT: {
      type: String,
      required: true,
    },
    questionName: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      askForDelete: false,
    }
  },
  computed: {
    itemIsQuestion() {
      return this.item.name === this.questionName
    },
    isMerchant() {
      return this.item.author.type === this.ROLE_MERCHANT
    },
  },
  methods: {
    onSelectOption(index) {
      this.$emit(index === 0 ? 'onRowClickEdit' : 'onRowClickDelete', {
        item: this.item,
        isQuestion: this.itemIsQuestion,
      })
    },
    date(str) {
      return new Date(str).toLocaleDateString(this.$i18n.locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.question-answer-row {
  padding: 20px 24px 20px 24px;
  width: 100%;

  &__header {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-start;

    &__date-delete {
      display: flex;
      flex-direction: row;
      margin-left: auto;

      &__ask {
        margin-top: -8px;
        &__no {
          margin-right: 8px;
        }
      }

      &__button {
        margin-top: -8px;
        margin-right: -12px;
      }
    }

    &__picture {
      margin-bottom: 12px;
      width: 40px;
      height: auto;
    }
    &__author {
      display: flex;
      flex-direction: column;
      &__name {
        margin-left: 12px;
        font-size: 13px;
      }

      &__type {
        margin-left: 12px;
        font-size: 12px;
        font-weight: lighter;
      }
    }
    &__date {
      margin-right: 8px;
      margin-left: auto;
    }
  }
  &__question {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    &__text {
      margin-top: 8px;
      margin-left: 16px;
      font-size: 13px;
    }
    &__upvote {
      margin: 0 8px 14px auto;
      padding: 0;
      min-width: 30px;
      min-height: 19px;
      color: #dcdee6;
    }
    .all-ready-up-voted {
      color: black;
    }
  }
}
</style>
