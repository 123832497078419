<template>
  <div class="ui-pagination" :class="{ 'ui-pagination--dark': $vuetify.theme.dark }">
    <div v-if="!isLoading">
      <template v-for="item in pagination.nbPages">
        <ui-button
          v-bind:key="item"
          class="ui-pagination__button"
          v-bind:class="{ 'selected-page-button': item === parseInt(pagination.page, 10) }"
          button="secondary"
          :label="`${item}`"
          :disabled="item === parseInt(pagination.page, 10)"
          @click="onClickPaginationButton(item)"
        />
      </template>
    </div>
    <skeleton-line v-else class="ui-pagination__skeleton" height="22px" width="22px" />
  </div>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
export default {
  name: 'UiPaginator',
  components: {
    UiButton,
    SkeletonLine,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    pagination: {
      type: Object,
      required: false,
      default: () => ({ page: 1, nbPages: 1 }),
    },
  },
  methods: {
    onClickPaginationButton(page) {
      this.$emit('onClickPaginationButton', page)
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: auto;
  border: 1px solid $grey-good-table-sep;
  border-right: 0;
  background: $grey-very-light;
  padding: 9px 0;
  width: 100%;
  min-height: 40px;
  overflow-x: auto;
  overflow-y: hidden;

  &--dark {
    background: $interface-dark-grade-1-color;
  }

  &__button {
    margin: 0 5px;
    border: 1px solid $grey-good-table-sep;
    border-radius: 16px;
    padding: 0;
    width: 22px;
    min-width: 22px;
    height: 22px;
    min-height: 22px;
  }
  .selected-page-button {
    background: #1faae6;
    color: white !important;
  }
}
</style>
