import { render, staticRenderFns } from "./RespondRow.vue?vue&type=template&id=5809c067&scoped=true&"
import script from "./RespondRow.vue?vue&type=script&lang=js&"
export * from "./RespondRow.vue?vue&type=script&lang=js&"
import style0 from "./RespondRow.vue?vue&type=style&index=0&id=5809c067&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5809c067",
  null
  
)

export default component.exports