<template>
  <div class="questions-panel">
    <!-- <div class="questions-panel__top" :class="{ 'questions-panel__top--dark': $vuetify.theme.dark }">
      <ui-input
        v-if="!isLoading"
        class="questions-panel__top__input"
        v-model="query"
        id="query"
        :label="$t('gmbQaEdit.searchChain.query')"
        design="minimalist"
        icon="search"
        @input="onInput"
      />
      <skeleton-input v-else margin-top="auto" margin-left="auto" margin-right="auto" width="90%" />
    </div> -->
    <!-- <div class="questions-panel__filters">
      <div class="questions-panel__filters__top">
        <ui-button
          class="questions-panel__filters__top__back"
          button="secondary"
          icon="chevron_left"
          label=""
          :iconOnly="true"
          @click="back"
        />
        <ui-title v-if="!isLoading" :title="locationName" />
        <skeleton-line v-else height="23px" />
      </div>
      <div class="questions-panel__filters__bottom">
        <ui-filter
          class="questions-panel__filters__bottom__all"
          :bubbleCount="10"
          :label="$t('gmbQaEdit.filters.all.label')"
          :disabled="isLoading"
          @onClick="onClickFilter"
        />
        <ui-filter
          class="questions-panel__filters__bottom__unreaded"
          :bubbleCount="10"
          :label="$t('gmbQaEdit.filters.unreaded.label')"
          :disabled="isLoading"
          :colors="{
            backgroundSelected: '#00afdf',
            bubbleVariant: 'blue-cerulean',
            bubbleVariantSelected: 'blue-viking',
          }"
          @onClick="onClickFilter"
        />
        <ui-filter
          class="questions-panel__filters__bottom__unreplied"
          :bubbleCount="10"
          :label="$t('gmbQaEdit.filters.unreplied.label')"
          :disabled="isLoading"
          :colors="{
            backgroundSelected: '#ef4036',
            bubbleVariant: 'red',
            bubbleVariantSelected: 'red',
          }"
          @onClick="onClickFilter"
        />
      </div>
    </div> -->
    <div class="questions-panel__locationName">
      <a class="questions-panel__locationName__link" target="_blank" :href="locationLink">
        {{ currentLocation.title }}
      </a>
    </div>
    <div class="questions-panel__create" :class="{ 'questions-panel__create--dark': $vuetify.theme.dark }">
      <span class="questions-panel__create__label">{{ $t('gmbQaEdit.label.createQuestion') }}</span>
      <ui-button
        class="questions-panel__create__button"
        button="secondary"
        icon="add"
        label=""
        :iconOnly="true"
        @click="onClickCreateQuestion"
      />
    </div>
    <ui-data-list
      class="questions-panel__list"
      :items="currentQuestions"
      :is-loading="isLoading"
      :is-scrolling="isScrolling"
      :no-more-data="pagination.page >= pagination.nbPages"
      @click="selectQuestionInDataList"
    >
      <template v-slot:item="{ item }">
        <question-answer-row
          :item="item"
          :ROLE_MERCHANT="ROLE_MERCHANT"
          :disabled-delete="true"
          :disabled-update="true"
        />
      </template>
    </ui-data-list>
    <ui-paginator :isLoading="isLoading" :pagination="pagination" @onClickPaginationButton="onClickPaginationButton" />
    <modal-simple-field
      :title="$t(`gmbQaEdit.label.createQuestion`)"
      :is-updating="isLoading"
      :data="modalAddQuestion.data"
      :label="modalAddQuestion.label"
      :obj-key="modalAddQuestion.objKey"
      :parameters="modalAddQuestion.parameters"
      @save="onModalConfirmSave"
    />
  </div>
</template>

<script>
import UiPaginator from '@/components/UI/Paginator.vue'
// import SkeletonInput from '@/components/Skeleton/Input.vue'
// import SkeletonLine from '@/components/Skeleton/Line.vue'
// import UiInput from '@/components/UI/Input.vue'
import UiButton from '@/components/UI/Button.vue'
// import UiFilter from '@/components/UI/Filter.vue'
// import UiTitle from '@/components/UI/Title.vue'
import UiDataList from '@/components/UI/DataList.vue'
import QuestionAnswerRow from '@/components/Qa/QuestionAnswerRow.vue'
import ModalSimpleField from '@/components/Modal/SimpleField.vue'
import { debounce } from '@/utils/func.util'
import { onScroll } from '@/mixins/scroll.mixin'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'QuestionsPanel',
  components: {
    // SkeletonInput,
    // SkeletonLine,
    // UiInput,
    UiButton,
    // UiFilter,
    // UiTitle,
    UiDataList,
    UiPaginator,
    QuestionAnswerRow,
    ModalSimpleField,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isScrolling: {
      type: Boolean,
      required: true,
    },
    accountId: {
      type: String,
      required: true,
    },
    locationId: {
      type: String,
      required: true,
    },
    locationName: {
      type: String,
      required: false,
      default: '',
    },
    currentQuestions: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: false,
      default: () => ({ page: 1, nbPages: 1 }),
    },
    ROLE_MERCHANT: {
      type: String,
      required: true,
    },
  },
  mixins: [onScroll],
  data() {
    return {
      query: '',
      modalAddQuestion: {
        objKey: 'text',
        data: '',
        label: this.$t('gmbQaEdit.modal.addQuestion.label'),
        parameters: {
          type: 'text',
          mode: 'input',
          required: true,
          number: null,
        },
      },
    }
  },
  computed: {
    ...mapState({
      currentLocation: state => state.gmb.currentLocation,
    }),
    locationLink() {
      return `https://www.google.com/search?q=${this.currentLocation.title} ${
        this.currentLocation.storefrontAddress?.locality || ''
      }`
    },
  },
  methods: {
    ...mapActions({
      createQuestion: 'qa/createQuestion',
      updateQuestion: 'qa/updateQuestion',
    }),
    onInput: debounce(function (value) {
      this.isUpdating = true
      this.$emit('search', value)
      this.isUpdating = false
    }, 300),
    back() {
      this.$router.go(-1)
    },
    selectQuestionInDataList(data) {
      this.$emit('selectQuestionInDataList', data)
    },
    onClickCreateQuestion() {
      this.modalAddQuestion.label = this.$t('gmbQaEdit.modal.addQuestion.label')
      this.$nextTick(() => {
        this.$modal.show('modal-simple-field')
      })
    },
    async onModalConfirmSave(question) {
      await this.createQuestion({
        account: this.accountId,
        location: this.locationId,
        ...question.objKey,
      })
      this.$modal.hide('modal-simple-field')
    },
    onClickPaginationButton(page) {
      this.$emit('onClickQuestionsPaginationButton', page)
    },
  },
}
</script>

<style lang="scss" scoped>
.questions-panel {
  ::v-deep .ui-input__wrapper > input {
    background: white;
    height: 38px;
  }
  ::v-deep .skeleton-input {
    margin-bottom: auto;
    height: 38px;
  }

  display: flex;
  flex-direction: column;
  border-right: 1px solid $grey-good-table-sep;
  width: 40%;
  overflow-y: auto;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: $screen-md) {
    width: 100%;
  }

  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: $input-color-bg;
    min-height: 64px;

    &--dark {
      border-top: 1px solid $grey-good-table-sep;
      border-left: 1px solid $grey-good-table-sep;
      background-color: hsl(var(--hue), 0%, 20%);
    }

    &__input {
      margin: auto;
      width: 90%;
    }
  }
  &__filters {
    margin: 16px 16px 8px 16px;

    &__top {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
    &__bottom {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      margin: 16px 34px 0 8px;

      &__unreaded {
        max-width: 107px;
      }
      &__unreplied {
        max-width: 107px;
      }
    }
  }

  &__locationName {
    margin: $gutter-mobile $gutter-tablet;
    font-size: $font-size-xl;
    font-weight: $font-weight-700;

    &__link {
      transition: color $transition-duration-default $transition-effect-default;
      cursor: pointer;
      color: inherit;
      &:hover {
        color: $blue-dodger;
      }
    }
  }

  &__create {
    display: flex;
    align-items: center;
    border-top: 1px solid $grey-good-table-sep;
    border-left: 1px solid $grey-good-table-sep;
    background: $grey-very-light;
    min-height: 41px;

    &--dark {
      background: $interface-dark-grade-1-color;
    }

    &__label {
      margin-left: $gutter-tablet;
      font-size: 12px;
      font-weight: 500;
    }
    &__button {
      margin-left: auto;
    }
  }
  &__list {
    border: 1px solid $grey-good-table-sep;
    border-right: 0;
    border-bottom: none;
    height: 100%;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.questions-panel::-webkit-scrollbar {
  display: none;
}
</style>
