<template>
  <div class="responses-panel">
    <skeleton-line v-if="isLoading" class="responses-panel__noSelectionSkeleton" height="23px" width="100%" />
    <span v-else-if="!selectedQuestion" class="responses-panel__no__selection">
      {{ $t('gmbQaEdit.noSelectedQuestion') }}
    </span>
    <span class="responses-panel__no__selection" v-else-if="haveSelectedQuestionAndEmpty">
      {{ $t('gmbQaEdit.selectedQuestionEmpty') }}
    </span>
    <div v-else class="responses-panel__responses">
      <ui-data-list
        class="responses-panel__responses__list"
        :items="questionAndResponseArray"
        :is-loading="isLoading"
        :is-scrolling="isScrolling"
        :no-more-data="!hasMoreAnswersToLoad"
      >
        <template v-slot:item="{ item, index }">
          <question-answer-row
            class="responses-panel__responses__item"
            :class="{ 'question-row': index === 0, 'responses-panel__responses__item--dark': $vuetify.theme.dark }"
            :item="item"
            :ROLE_MERCHANT="ROLE_MERCHANT"
            :disabled-update="updateIsDisabled(item)"
            :question-name="selectedQuestion.name"
            @onRowClickEdit="onRowOptionsClickEditResponse"
            @onRowClickDelete="onRowOptionsClickDeleteResponse"
          />
        </template>
      </ui-data-list>
      <ui-button
        v-if="!responseRowDisplayed && !merchantAlreadyRespond"
        class="responses-panel__responses__button"
        button="primary"
        :label="$t('gmbQaEdit.button.respond')"
        @click="onClickRespondButton"
      />
      <respond-row
        v-if="responseRowDisplayed && respondAccount != null"
        :isLoading="isLoading"
        :name="respondAccount.locationName || ''"
        :text="answerToUpdate ? answerToUpdate.text : ''"
        :picture="respondAccount.clientLogo"
        :type="respondAccount.role"
        :is-edit="isEditedQa"
        @onClickCancel="responseRowDisplayed = false"
        @onClickPublish="isConfirmPopoverDisplayed = true"
        @onTextareaInputChange="onInputResponseChange"
      />
    </div>
    <confirm-popover
      v-if="isConfirmPopoverDisplayed"
      :title="$t(`gmbQaEdit.confirmPopover.${isEditedQa ? 'edit' : 'publish'}.title`)"
      :is-loading="isConfirmPopoverLoading"
      @onClickNo="isConfirmPopoverDisplayed = false"
      @onClickYes="onConfirmResponseModalClickYes"
    />
  </div>
</template>

<script>
import SkeletonLine from '@/components/Skeleton/Line.vue'
import UiButton from '@/components/UI/Button.vue'
import UiDataList from '@/components/UI/DataList.vue'
import QuestionAnswerRow from '@/components/Qa/QuestionAnswerRow.vue'
import RespondRow from '@/components/Qa/RespondRow.vue'
import ConfirmPopover from '@/components/UI/ConfirmPopover.vue'
import { onScroll } from '@/mixins/scroll.mixin'
import { mapActions } from 'vuex'

export default {
  name: 'ResponsesPanel',
  components: {
    SkeletonLine,
    UiButton,
    UiDataList,
    QuestionAnswerRow,
    RespondRow,
    ConfirmPopover,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isScrolling: {
      type: Boolean,
      required: true,
    },
    hasMoreAnswersToLoad: {
      type: Boolean,
      required: true,
    },
    currentAccountName: {
      type: String,
      required: true,
    },
    selectedQuestion: {
      type: Object,
      required: false,
    },
    respondAccount: {
      type: Object,
      required: false,
    },
    ROLE_MERCHANT: {
      type: String,
      required: true,
    },
  },
  mixins: [onScroll],
  data: () => ({
    responseRowDisplayed: false,
    isConfirmPopoverDisplayed: false,
    isConfirmPopoverLoading: false,
    responseValue: '',
    answerToUpdate: null,
  }),
  watch: {
    isLoading() {
      this.responseRowDisplayed = false
      this.answerToUpdate = null
    },
  },
  computed: {
    questionAndResponseArray() {
      return this.selectedQuestion
        ? [this.selectedQuestion, ...(this.selectedQuestion.topAnswers?.length ? this.selectedQuestion.topAnswers : [])]
        : []
    },
    isEditedQa() {
      return !!this.answerToUpdate?.name
    },
    merchantAlreadyRespond() {
      if (this.selectedQuestion?.topAnswers) {
        return this.selectedQuestion?.topAnswers.filter(answer => answer.author.type === 'MERCHANT').length > 0
      }
      return false
    },
    haveSelectedQuestionAndEmpty() {
      if (this.selectedQuestion !== null && typeof this.selectedQuestion === 'object') {
        return Object.keys(this.selectedQuestion).length <= 0
      }
      return false
    },
  },
  methods: {
    ...mapActions({
      deleteQuestion: 'qa/deleteQuestion',
      updateQuestion: 'qa/updateQuestion',
      createAnswer: 'qa/createAnswer',
      updateAnswer: 'qa/updateAnswer',
      deleteAnswer: 'qa/deleteAnswer',
    }),
    updateIsDisabled(item) {
      if (this.selectedQuestion.topAnswers?.length) {
        return item.name === this.selectedQuestion.name
      }
      return false
    },
    updateResponse() {
      this.responseRowDisplayed = false
      this.$emit('dataUpdate')
    },
    onInputResponseChange(value) {
      this.responseValue = value
    },
    onClickRespondButton() {
      this.answerToUpdate = null
      this.responseRowDisplayed = true
    },
    async onConfirmResponseModalClickYes() {
      this.isConfirmPopoverLoading = true
      if (this.answerToUpdate == null) {
        await this.createAnswer({
          account: this.currentAccountName,
          location: this.selectedQuestion.name.split('/question')[0],
          id: this.selectedQuestion.name,
          text: this.responseValue,
        })
      } else if (this.answerToUpdate.name === this.selectedQuestion.name) {
        await this.updateQuestion({
          account: this.currentAccountName,
          location: this.selectedQuestion.name.split('/question')[0],
          text: this.responseValue,
          id: this.selectedQuestion.name,
        })
      } else {
        await this.updateAnswer({
          account: this.currentAccountName,
          location: this.selectedQuestion.name.split('/question')[0],
          id: this.selectedQuestion.name,
          text: this.responseValue,
        })
      }
      this.isConfirmPopoverDisplayed = false
      this.isConfirmPopoverLoading = false
      this.updateResponse()
    },
    async onRowOptionsClickEditResponse({ item }) {
      this.answerToUpdate = item
      this.responseRowDisplayed = true
    },
    async onRowOptionsClickDeleteResponse({ isQuestion }) {
      const data = {
        account: this.currentAccountName,
        location: this.selectedQuestion.name.split('/question')[0],
        id: this.selectedQuestion.name,
      }
      ;(await isQuestion) ? this.deleteQuestion(data) : this.deleteAnswer(data)
      this.updateResponse()
    },
  },
}
</script>

<style lang="scss" scoped>
.responses-panel {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 60%;

  @media (max-width: $screen-md) {
    width: 100%;
  }

  &__no__selection {
    margin: auto;
  }
  &__noSelectionSkeleton {
    margin: auto;
  }

  &__responses {
    margin: 24px;
    overflow-y: auto;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::v-deep .ui-data-list__item {
      margin-right: 0;
      margin-left: 57px;
      border-bottom: 0;

      &:first-child {
        margin-left: 0;
      }
    }

    &__item {
      margin-bottom: 16px;
      border: 1px solid $grey-borders;
      border-radius: 12px;
      background-clip: border-box;

      &--dark {
        background-color: $interface-dark-grade-1-color !important;
      }
    }
    .question-row {
      border: 0;
      background: $grey-very-light;
    }

    &__button {
      display: flex;
      margin: auto;
      border: 0;
      background: linear-gradient(to left, $blue-clear-intense, $blue-royal);
      height: 32px;
      min-height: 32px;
      color: white !important;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &__responses::-webkit-scrollbar {
    display: none;
  }
}
</style>
